'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  Accordion: () => accordion_exports,
  Alert: () => Alert,
  AlertDescription: () => AlertDescription,
  AlertTitle: () => AlertTitle,
  Avatar: () => Avatar,
  AvatarGroup: () => AvatarGroup,
  Button: () => Button,
  ButtonGroup: () => ButtonGroup,
  Card: () => Card,
  CardContent: () => CardContent,
  CardDescription: () => CardDescription,
  CardFooter: () => CardFooter,
  CardHeader: () => CardHeader,
  CardTitle: () => CardTitle,
  Checkbox: () => Checkbox,
  Dropdown: () => dropdown_exports,
  Form: () => Form,
  FormControl: () => FormControl,
  FormDescription: () => FormDescription,
  FormField: () => FormField,
  FormItem: () => FormItem,
  FormItemContext: () => FormItemContext,
  FormLabel: () => FormLabel,
  FormMessage: () => FormMessage,
  FormSection: () => FormSection,
  Group: () => Group3,
  HoverCard: () => HoverCard,
  IconButton: () => IconButton,
  Input: () => Input,
  Label: () => Label3,
  LoadingIcon: () => LoadingIcon,
  Popover: () => popover_exports,
  RadioGroup: () => radio_group_exports,
  SearchInput: () => SearchInput,
  Select: () => select_exports,
  Selector: () => Selector,
  SideDialog: () => SideDialog,
  Switch: () => Switch,
  Text: () => Text,
  Textarea: () => Textarea,
  Toast: () => Toast,
  ToastAction: () => ToastAction,
  ToastClose: () => ToastClose,
  ToastDescription: () => ToastDescription,
  ToastProvider: () => ToastProvider,
  ToastTitle: () => ToastTitle,
  ToastViewport: () => ToastViewport,
  Toaster: () => Toaster,
  Tooltip: () => Tooltip,
  buttonVariants: () => buttonVariants,
  reducer: () => reducer,
  toast: () => toast,
  useFormField: () => useFormField,
  useToast: () => useToast
});
module.exports = __toCommonJS(src_exports);

// src/accordion/index.tsx
var accordion_exports = {};
__export(accordion_exports, {
  Content: () => AccordionContent,
  Item: () => AccordionItem,
  Root: () => Accordion,
  Trigger: () => AccordionTrigger
});

// src/accordion/accordion.tsx
var React = __toESM(require("react"));
var AccordionPrimitive = __toESM(require("@radix-ui/react-accordion"));
var import_lucide_react = require("lucide-react");

// src/lib/utils.ts
var import_clsx = require("clsx");
var import_tailwind_merge = require("tailwind-merge");
function cn(...inputs) {
  const classes = (0, import_tailwind_merge.twMerge)((0, import_clsx.clsx)(inputs));
  return classes;
}

// src/accordion/accordion.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Accordion = AccordionPrimitive.Root;
var AccordionItem = React.forwardRef((_a, ref) => {
  var _b = _a, { children, className } = _b, props = __objRest(_b, ["children", "className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(AccordionPrimitive.Item, __spreadProps(__spreadValues({ className: cn(className), ref }, props), { children: [
    children,
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
      "div",
      {
        "data-orientation": "horizontal",
        role: "none",
        className: "h-0.5 min-h-[0.125rem] min-w-full rounded bg-gray-950/5 dark:bg-white/5"
      }
    )
  ] }));
});
AccordionItem.displayName = "AccordionItem";
var AccordionTrigger = React.forwardRef((_a, ref) => {
  var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(AccordionPrimitive.Header, { className: "flex flex-col", children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    AccordionPrimitive.Trigger,
    __spreadProps(__spreadValues({
      className: cn(
        "flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
        className
      ),
      ref
    }, props), {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, { children: [
        children,
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_lucide_react.ChevronDownIcon, { className: "h-4 w-4 shrink-0 text-gray-500 transition-transform duration-200 dark:text-gray-500" })
      ] })
    })
  ) });
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
var AccordionContent = React.forwardRef((_a, ref) => {
  var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    AccordionPrimitive.Content,
    __spreadProps(__spreadValues({
      className: "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm",
      ref
    }, props), {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("div", { className: cn("pb-4 pt-0", className), children })
    })
  );
});
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

// src/alert/alert.tsx
var React2 = __toESM(require("react"));
var import_class_variance_authority = require("class-variance-authority");
var import_jsx_runtime2 = require("react/jsx-runtime");
var alertVariants = (0, import_class_variance_authority.cva)(
  "relative w-full rounded-lg border border-gray-200 px-4 py-3 text-sm dark:border-gray-700 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-3 [&>svg]:text-gray-700 [&>svg~*]:pl-7",
  {
    variants: {
      variant: {
        default: "bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-400",
        destructive: "border-rose-600/50 text-rose-600 dark:border-rose-600 [&>svg]:text-rose-600"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);
var Alert = React2.forwardRef((_a, ref) => {
  var _b = _a, { className, variant } = _b, props = __objRest(_b, ["className", "variant"]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    "div",
    __spreadValues({
      className: cn(alertVariants({ variant }), className),
      ref,
      role: "alert"
    }, props)
  );
});
Alert.displayName = "Alert";
var AlertTitle = React2.forwardRef((_a, ref) => {
  var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    "h5",
    __spreadProps(__spreadValues({
      className: cn("mb-1 font-medium leading-none tracking-tight", className),
      ref
    }, props), {
      children
    })
  );
});
AlertTitle.displayName = "AlertTitle";
var AlertDescription = React2.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    "div",
    __spreadValues({
      className: cn("text-sm [&_p]:leading-relaxed", className),
      ref
    }, props)
  );
});
AlertDescription.displayName = "AlertDescription";

// src/avatar/avatar.tsx
var React3 = __toESM(require("react"));
var RadixAvatar = __toESM(require("@radix-ui/react-avatar"));
var import_class_variance_authority2 = require("class-variance-authority");
var import_jsx_runtime3 = require("react/jsx-runtime");
function AvatarInitials({
  name,
  size = "md"
}) {
  const initials = React3.useMemo(() => {
    const cleanName = name.replace(/\s+/g, " ").trim();
    const [firstName, lastName] = cleanName.split(" ");
    if (size !== "xs") {
      return firstName && lastName ? `${firstName[0]}${lastName[0]}`.toUpperCase() : firstName[0].toUpperCase();
    }
    return firstName ? firstName[0].toUpperCase() : "";
  }, [name, size]);
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("span", { className: "align-middle tracking-normal", role: "img", children: initials });
}
function Avatar({
  border = true,
  name,
  size = "md",
  src
}) {
  const avatarVariants = (0, import_class_variance_authority2.cva)(
    cn(
      "flex items-center justify-center rounded-full bg-gray-200 tracking-wider text-gray-600 focus-within:outline-none focus:outline-none dark:bg-gray-700 dark:text-gray-300",
      { "ring ring-white dark:ring-gray-900": border }
    ),
    {
      variants: {
        size: {
          xs: "h-6 w-6 min-w-[1rem] text-sm font-normal",
          sm: "h-8 w-8 min-w-[1.5rem] text-sm font-normal",
          md: "h-10 w-10 min-w-[1.5rem] text-base font-normal",
          lg: "h-12 w-12 min-w-[2rem] text-lg font-normal",
          xl: "h-16 w-16 min-w-[2.5rem] text-2xl font-light"
        }
      },
      defaultVariants: {
        size: "md"
      }
    }
  );
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(RadixAvatar.Root, { className: cn(avatarVariants({ size })), children: [
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
      RadixAvatar.Image,
      {
        alt: name,
        className: cn("h-full w-full rounded-full object-cover"),
        src
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(RadixAvatar.Fallback, { children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(AvatarInitials, { name, size }) })
  ] });
}
function AvatarGroup({
  children,
  size = "md"
}) {
  const maxAvatars = 4;
  const resizedChildren = React3.Children.map(children, (element) => {
    if (!React3.isValidElement(element)) {
      return;
    }
    const typedElement = element;
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(Avatar, __spreadProps(__spreadValues({}, typedElement.props), { size }));
  });
  const avatars = React3.Children.toArray(resizedChildren);
  const exceedsMax = avatars.length > maxAvatars;
  const shownAvatars = exceedsMax ? avatars.slice(0, maxAvatars - 1) : avatars;
  const remainingAvatars = exceedsMax ? avatars.slice(maxAvatars - 1) : null;
  const marginVariants = (0, import_class_variance_authority2.cva)("", {
    variants: {
      size: {
        xs: "-mr-1",
        sm: "-mr-1",
        md: "-mr-2",
        lg: "-mr-2",
        xl: "-mr-4"
      },
      defaultVariants: {
        size: "md"
      }
    }
  });
  const avatarGroupVariants = (0, import_class_variance_authority2.cva)(
    "-mr-1 flex items-center justify-center rounded-full bg-gray-200 text-gray-600 ring ring-white focus-within:outline-none focus:outline-none dark:bg-gray-700 dark:text-gray-300 dark:ring-gray-900",
    {
      variants: {
        size: {
          xs: "h-6 w-6 min-w-[1rem] text-xs font-medium",
          sm: "h-8 w-8 min-w-[1.5rem] text-sm font-normal",
          md: "h-10 w-10 min-w-[1.5rem] text-base font-normal",
          lg: "h-12 w-12 min-w-[2rem] text-lg font-normal",
          xl: "h-16 w-16 min-w-[2.5rem] text-2xl font-light"
        }
      },
      defaultVariants: {
        size: "md"
      }
    }
  );
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", { className: cn("flex"), children: [
    shownAvatars.map((avatar, index) => {
      const key = `avatar${index.toString()}`;
      return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("div", { className: cn(marginVariants({ size })), children: avatar }, key);
    }),
    remainingAvatars && remainingAvatars.length > 0 ? /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("div", { className: cn(avatarGroupVariants({ size })), children: remainingAvatars.length < 100 ? `+${remainingAvatars.length.toString()}` : `99+` }) : null
  ] });
}

// src/button/button.tsx
var import_react = require("react");
var import_class_variance_authority3 = require("class-variance-authority");
var React4 = __toESM(require("react"));

// src/loading-icon/loading-icon.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
function LoadingIcon({
  size = "md"
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsxs)(
    "svg",
    {
      className: [
        {
          xs: "h-3 w-3",
          sm: "h-3.5 w-3.5",
          md: "h-4 w-4",
          lg: "h-5 w-5",
          xl: "h-10 w-10"
        }[size] || "",
        "animate-spin"
      ].join(" "),
      fill: "none",
      viewBox: "0 0 24 24",
      xmlns: "http://www.w3.org/2000/svg",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
          "circle",
          {
            className: "opacity-25",
            cx: "12",
            cy: "12",
            r: "10",
            stroke: "currentColor",
            strokeWidth: "4"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
          "path",
          {
            className: "opacity-75",
            d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
            fill: "currentColor"
          }
        )
      ]
    }
  );
}

// src/button/button.tsx
var import_jsx_runtime5 = require("react/jsx-runtime");
var buttonVariants = (0, import_class_variance_authority3.cva)(
  "focus-outline inline-flex items-center justify-center gap-x-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-md border transition duration-150 ease-in-out focus:z-10",
  {
    variants: {
      variant: {
        default: "border-gray-300 bg-gray-300/20 text-gray-700 hover:bg-gray-300/40 hover:text-gray-800 active:bg-gray-300/60 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600/80 dark:hover:text-gray-200 dark:active:bg-gray-600/60",
        primary: "border-transparent bg-blue-500 text-white hover:bg-blue-700 active:bg-blue-900",
        negative: "border-gray-300 bg-gray-300/20 text-red-600 hover:border-transparent hover:bg-red-700 hover:text-white active:border-transparent active:bg-red-900 active:text-white dark:border-gray-600 dark:text-red-500 dark:hover:bg-red-800/80",
        ghost: "border-transparent bg-gray-300 bg-opacity-0 text-gray-600 hover:bg-opacity-30 hover:text-gray-600 active:bg-opacity-50 dark:bg-gray-600 dark:bg-opacity-0 dark:text-gray-300 dark:hover:bg-opacity-30 dark:hover:text-gray-300",
        secondary: "border-transparent bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-600 active:bg-opacity-50 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600",
        link: "border-transparent bg-transparent text-gray-600 !outline-offset-0 hover:text-blue-500 hover:underline focus-visible:text-blue-500 focus-visible:underline dark:text-gray-300 hover:dark:text-blue-400 focus-visible:dark:text-blue-400",
        outline: "border-gray-950/10 bg-gray-400/5 text-gray-700 focus-within:border-gray-950/20 hover:border-gray-950/20 hover:bg-gray-400/10 hover:text-gray-800 active:border-gray-950/30 active:bg-gray-400/20 dark:border-white/10 dark:bg-gray-500/5 dark:text-gray-300 dark:focus-within:border-white/15 dark:hover:border-white/15 dark:hover:bg-gray-500/10 dark:hover:text-gray-200 dark:active:border-white/20 dark:active:bg-gray-500/20",
        "outline-primary": "border-blue-400 bg-blue-400/5 text-blue-500 focus-within:border-blue-400 hover:border-blue-400 hover:bg-blue-400/10 hover:text-blue-600 active:border-blue-400 active:bg-blue-400/20 dark:border-blue-600 dark:bg-blue-500/5 dark:text-blue-300 dark:focus-within:border-blue-500 dark:hover:border-blue-500 dark:hover:bg-blue-500/10 dark:hover:text-blue-200 dark:active:border-blue-500 dark:active:bg-blue-500/20"
      },
      size: {
        xs: "font-base h-5 text-sm",
        sm: "font-base h-7 py-1.5 text-sm",
        md: "h-8 py-1.5 text-sm font-medium",
        lg: "h-9 py-2 font-medium"
      },
      state: {
        enabled: "cursor-pointer",
        disabled: "hover:none pointer-events-none cursor-default opacity-50",
        loading: "pointer-events-none cursor-wait opacity-70"
      },
      textAlign: {
        left: "justify-start",
        right: "justify-end",
        center: "justify-center"
      },
      content: {
        default: "",
        icon: ""
      }
    },
    compoundVariants: [
      {
        content: "icon",
        size: "xs",
        className: "px-0.5"
      },
      {
        content: "icon",
        size: "sm",
        className: "px-1"
      },
      {
        content: "icon",
        size: "md",
        className: "px-1.5"
      },
      {
        content: "icon",
        size: "lg",
        className: "px-6"
      },
      {
        content: "default",
        size: "xs",
        className: "px-0.5"
      },
      {
        content: "default",
        size: "sm",
        className: "px-1.5"
      },
      {
        content: "default",
        size: "md",
        className: "px-3"
      },
      {
        content: "default",
        size: "lg",
        className: "px-6"
      }
    ],
    defaultVariants: {
      variant: "default",
      size: "md",
      state: "enabled",
      content: "default",
      textAlign: "center"
    }
  }
);
var Button = React4.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      variant = "default",
      size = "md",
      state = "enabled",
      leftIcon,
      rightIcon,
      type = "button",
      className,
      id,
      fill,
      textAlign = "center"
    } = _b, props = __objRest(_b, [
      "variant",
      "size",
      "state",
      "leftIcon",
      "rightIcon",
      "type",
      "className",
      "id",
      "fill",
      "textAlign"
    ]);
    const [calculatedIcon, setCalculatedIcon] = (0, import_react.useState)(leftIcon);
    (0, import_react.useEffect)(() => {
      if (state === "loading") {
        setCalculatedIcon(/* @__PURE__ */ (0, import_jsx_runtime5.jsx)(LoadingIcon, { size }));
      } else {
        setCalculatedIcon(leftIcon);
      }
    }, [leftIcon, size, state]);
    const content = React4.useMemo(() => {
      return props.children ? "default" : "icon";
    }, [props.children]);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
      "button",
      __spreadProps(__spreadValues({
        className: cn(
          fill ? "w-full" : "",
          buttonVariants({ content, size, state, textAlign, variant }),
          className
        ),
        disabled: state === "disabled" || state === "loading",
        id,
        ref,
        type
      }, props), {
        children: /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)(
          "div",
          {
            className: [
              "flex items-center justify-between",
              {
                xs: "gap-x-1",
                sm: "gap-x-1",
                md: "gap-x-1.5",
                lg: "gap-x-2"
              }[size]
            ].join(" "),
            children: [
              calculatedIcon ? /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("span", { children: calculatedIcon }) : null,
              props.children ? props.children : null,
              rightIcon ? /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("span", { children: rightIcon }) : null
            ]
          }
        )
      })
    );
  }
);
Button.displayName = "Button";

// src/button/button-group.tsx
var React5 = __toESM(require("react"));
var import_jsx_runtime6 = require("react/jsx-runtime");
function ButtonGroup(_a) {
  var _b = _a, {
    align = "start",
    direction = "horizontal",
    fill = false,
    gap = "md",
    inline = false,
    size,
    children,
    textAlign = "center"
  } = _b, props = __objRest(_b, [
    "align",
    "direction",
    "fill",
    "gap",
    "inline",
    "size",
    "children",
    "textAlign"
  ]);
  const _a2 = props, { className } = _a2, rest = __objRest(_a2, ["className"]);
  const updatedChildren = React5.Children.map(children, (child) => {
    if (React5.isValidElement(child)) {
      if (typeof child.type === "function" && child.type.displayName === "Button") {
        const {
          className: buttonClassName,
          fill: buttonFill,
          size: buttonSize,
          textAlign: buttonTextAlign
        } = child.props;
        const newButtonProps = {
          className: fill ? `${buttonClassName != null ? buttonClassName : ""} flex-1` : buttonClassName,
          fill: buttonFill ? buttonFill : fill,
          size: buttonSize ? buttonSize : size,
          textAlign: buttonTextAlign ? buttonTextAlign : textAlign
        };
        return React5.cloneElement(child, newButtonProps);
      }
    }
    return child;
  });
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
    "div",
    __spreadProps(__spreadValues({
      className: [
        inline ? "inline-flex" : "flex",
        direction === "vertical" ? "flex-col" : "flex-row",
        "items-center",
        {
          start: "justify-start",
          center: "justify-center",
          end: "justify-end",
          between: "justify-between"
        }[align],
        fill ? "w-full" : "",
        {
          none: "gap-x-0",
          xs: "gap-x-1",
          sm: "gap-x-2",
          md: "gap-x-4",
          lg: "gap-x-8"
        }[gap],
        "max-w-full",
        className
      ].join(" ")
    }, rest), {
      children: updatedChildren
    })
  );
}

// src/button/icon-button.tsx
var React6 = __toESM(require("react"));

// src/tooltip/tooltip.tsx
var RadixTooltip = __toESM(require("@radix-ui/react-tooltip"));
var import_jsx_runtime7 = require("react/jsx-runtime");
function Tooltip({
  asChild = false,
  content,
  delayDuration = 0,
  side = "bottom",
  size = "sm",
  sideOffset,
  trigger
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(RadixTooltip.Provider, { delayDuration, children: /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(RadixTooltip.Root, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(RadixTooltip.Trigger, { asChild, children: trigger }),
    /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(
      RadixTooltip.Content,
      {
        className: [
          "z-50 max-w-[16rem] rounded-md text-center",
          {
            xs: "px-1.5 py-1 text-xs",
            sm: "p-2 text-xs",
            md: "p-2 text-sm",
            lg: "p-3 text-base"
          }[size],
          "bg-gray-800 text-white dark:bg-gray-600 dark:text-gray-200"
        ].join(" "),
        side,
        sideOffset,
        tabIndex: -1,
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
            RadixTooltip.Arrow,
            {
              className: " fill-gray-800 dark:fill-gray-600",
              offset: 12
            }
          ),
          content
        ]
      }
    )
  ] }) });
}

// src/button/icon-button.tsx
var import_jsx_runtime8 = require("react/jsx-runtime");
var IconButton = React6.forwardRef(
  function IconButton2(_a, ref) {
    var _b = _a, {
      className,
      icon,
      label,
      variant = "default",
      size = "sm",
      showTooltip = true,
      state = "enabled",
      tooltipSize = "sm",
      onClick
    } = _b, props = __objRest(_b, [
      "className",
      "icon",
      "label",
      "variant",
      "size",
      "showTooltip",
      "state",
      "tooltipSize",
      "onClick"
    ]);
    const iconWidthHeight = {
      xs: "w-3.5 h-3.5",
      sm: "w-5 h-5",
      md: "w-5 h-5 my-0.5",
      lg: "w-6 h-6 -mx-3 my-0.5"
    }[size];
    const iconWidthHeightSize = {
      xs: 16,
      sm: 20,
      md: 20,
      lg: 24
    }[size];
    const updatedClassName = cn(icon.props.className, iconWidthHeight);
    const styledIcon = React6.cloneElement(icon, {
      className: updatedClassName,
      size: iconWidthHeightSize
    });
    const button = /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      Button,
      __spreadProps(__spreadValues({
        className,
        leftIcon: styledIcon,
        onClick,
        size,
        state,
        variant
      }, props), {
        ref
      })
    );
    return state === "disabled" || !showTooltip ? button : /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      Tooltip,
      {
        asChild: true,
        content: label,
        sideOffset: 2,
        size: tooltipSize,
        trigger: button
      }
    );
  }
);
IconButton.displayName = "IconButton";

// src/card/card.tsx
var React7 = __toESM(require("react"));
var import_jsx_runtime9 = require("react/jsx-runtime");
var Card = React7.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
    "div",
    __spreadValues({
      ref,
      className: cn(
        "rounded-lg border bg-card text-card-foreground shadow-sm",
        className
      )
    }, props)
  );
});
Card.displayName = "Card";
var CardHeader = React7.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
    "div",
    __spreadValues({
      ref,
      className: cn("flex flex-col space-y-1.5 p-6", className)
    }, props)
  );
});
CardHeader.displayName = "CardHeader";
var CardTitle = React7.forwardRef((_a, ref) => {
  var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
    "h3",
    __spreadProps(__spreadValues({
      ref,
      className: cn(
        "text-2xl font-semibold leading-none tracking-tight",
        className
      )
    }, props), {
      children
    })
  );
});
CardTitle.displayName = "CardTitle";
var CardDescription = React7.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
    "p",
    __spreadValues({
      ref,
      className: cn("text-sm text-muted-foreground", className)
    }, props)
  );
});
CardDescription.displayName = "CardDescription";
var CardContent = React7.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("div", __spreadValues({ ref, className: cn("p-6 pt-0", className) }, props));
});
CardContent.displayName = "CardContent";
var CardFooter = React7.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
    "div",
    __spreadValues({
      ref,
      className: cn("flex items-center p-6 pt-0", className)
    }, props)
  );
});
CardFooter.displayName = "CardFooter";

// src/dropdown/index.tsx
var dropdown_exports = {};
__export(dropdown_exports, {
  Content: () => Content4,
  Group: () => Group2,
  Item: () => Item3,
  Label: () => Label2,
  Root: () => Root5,
  Separator: () => Separator2,
  Sub: () => Sub2,
  SubContent: () => SubContent2,
  Trigger: () => import_react_dropdown_menu.Trigger
});

// src/dropdown/dropdown.tsx
var DropdownMenu = __toESM(require("@radix-ui/react-dropdown-menu"));
var import_react_dropdown_menu = require("@radix-ui/react-dropdown-menu");
var import_framer_motion = require("framer-motion");
var import_lucide_react2 = require("lucide-react");
var React8 = __toESM(require("react"));
var import_class_variance_authority4 = require("class-variance-authority");
var import_jsx_runtime10 = require("react/jsx-runtime");
function addSpacingToChildren(children, spacing) {
  const updatedChildren = React8.Children.map(children, (child) => {
    var _a;
    if (React8.isValidElement(child)) {
      if ((_a = child.type.displayName) == null ? void 0 : _a.startsWith(
        "Dropdown"
      )) {
        const _b = child.props, { spacing: _childSpacing } = _b, rest = __objRest(_b, ["spacing"]);
        const newChildProps = __spreadValues({
          spacing
        }, rest);
        return React8.cloneElement(child, newChildProps);
      }
    }
    return child;
  });
  return updatedChildren;
}
function Separator2() {
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.Separator, { className: "my-2 h-px bg-gray-200 dark:bg-gray-600" });
}
Separator2.displayName = DropdownMenu.Separator.displayName;
function Label2({
  children,
  spacing = "default"
}) {
  const updatedChildren = addSpacingToChildren(children, spacing);
  const dropdownLabelVariants = (0, import_class_variance_authority4.cva)(
    "text-left text-xs text-gray-600 dark:text-gray-400",
    {
      variants: {
        spacing: {
          default: "px-4 py-2",
          compact: "px-2 py-1"
        }
      }
    }
  );
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.Label, { className: cn(dropdownLabelVariants({ spacing })), children: updatedChildren ? updatedChildren : null });
}
Label2.displayName = DropdownMenu.Label.displayName;
function Group2(_a) {
  var _b = _a, {
    children,
    spacing = "default",
    type = "default"
  } = _b, props = __objRest(_b, [
    "children",
    "spacing",
    "type"
  ]);
  const updatedChildren = addSpacingToChildren(children, spacing);
  const dropdownGroupVariants = (0, import_class_variance_authority4.cva)("flex flex-col", {
    variants: {
      spacing: {
        default: "min-w-[12rem] p-2",
        compact: "min-w-0 p-1.5"
      }
    }
  });
  const value = "value" in props ? props.value : void 0;
  const onValueChange = "onValueChange" in props ? props.onValueChange : void 0;
  const group = {
    default: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.Group, { className: dropdownGroupVariants({ spacing }), children: updatedChildren ? updatedChildren : null }),
    radio: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
      DropdownMenu.RadioGroup,
      {
        className: dropdownGroupVariants({ spacing }),
        onValueChange,
        value,
        children: updatedChildren ? updatedChildren : null
      }
    )
  }[type];
  return group;
}
Group2.displayName = DropdownMenu.Group.displayName;
function Item3(_a) {
  var _b = _a, {
    icon,
    onSelect,
    children,
    rightSlot,
    spacing = "default",
    type = "default"
  } = _b, props = __objRest(_b, [
    "icon",
    "onSelect",
    "children",
    "rightSlot",
    "spacing",
    "type"
  ]);
  var _a2;
  const updatedChildren = addSpacingToChildren(children, spacing);
  const dropdownItemVariants = (0, import_class_variance_authority4.cva)(
    "flex items-center gap-1.5 text-sm focus:outline-none ",
    {
      variants: {
        disabled: {
          true: "hover:none pointer-events-none cursor-default opacity-50",
          false: "cursor-pointer hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-gray-200 dark:focus:bg-gray-600 dark:focus:text-gray-200"
        },
        spacing: {
          default: "gap-1.5 rounded-lg py-1.5 pl-4 pr-2 font-medium text-gray-800  dark:text-gray-300",
          compact: "gap-1 rounded-md py-1 pl-2 pr-2 font-normal text-gray-700 dark:text-gray-400"
        }
      }
    }
  );
  const checked = "checked" in props ? props.checked : void 0;
  const onCheckedChange = "onCheckedChange" in props ? props.onCheckedChange : void 0;
  const value = "value" in props ? props.value : void 0;
  const styles = dropdownItemVariants({
    spacing,
    disabled: (_a2 = props.disabled) != null ? _a2 : false
  });
  const selectedIndicator = {
    checkbox: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.ItemIndicator, { className: "ml-auto", children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(import_lucide_react2.CheckIcon, { size: 14 }) }),
    radio: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.ItemIndicator, { className: "ml-auto", children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(import_lucide_react2.DotIcon, { size: 24 }) }),
    trigger: null,
    default: null
  }[type];
  const content = /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_jsx_runtime10.Fragment, { children: [
    icon ? /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("span", { className: "text-gray-500 dark:text-gray-400", children: icon }) : null,
    updatedChildren ? /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("span", { className: "inline-flex", children: updatedChildren }) : null,
    rightSlot ? /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("span", { className: "ml-auto text-gray-400 dark:text-gray-500", children: rightSlot }) : null,
    selectedIndicator ? selectedIndicator : null
  ] });
  const item = {
    default: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
      DropdownMenu.Item,
      {
        className: styles,
        onSelect,
        children: content
      }
    ),
    radio: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
      DropdownMenu.RadioItem,
      {
        className: styles,
        onSelect,
        value: value != null ? value : "",
        children: content
      }
    ),
    checkbox: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
      DropdownMenu.CheckboxItem,
      {
        checked,
        className: styles,
        onCheckedChange,
        onSelect,
        children: content
      }
    ),
    trigger: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.SubTrigger, { className: styles, children: content })
  }[type];
  return item;
}
Item3.displayName = DropdownMenu.Item.displayName;
function MenuContent({
  children,
  className,
  spacing = "default"
}) {
  const updatedChildren = addSpacingToChildren(children, spacing);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
    import_framer_motion.motion.div,
    {
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.2,
          ease: "easeOut"
        }
      },
      className,
      exit: {
        opacity: 0,
        y: "-0.25rem",
        transition: {
          duration: 0.1,
          ease: "easeIn"
        }
      },
      initial: { opacity: 0, y: "-0.75rem" },
      children: updatedChildren ? updatedChildren : null
    }
  );
}
MenuContent.displayName = "DropdownMenuContent";
var dropdownSpacingVariants = (0, import_class_variance_authority4.cva)(
  "w-full border border-gray-200 bg-white dark:border-gray-800 dark:bg-gray-700",
  {
    variants: {
      spacing: {
        default: "min-w-[12rem] rounded-xl shadow-xl",
        compact: "min-w-0 rounded-lg shadow-lg"
      }
    }
  }
);
function Content4(_a) {
  var _b = _a, {
    children,
    className,
    sideOffset = 0,
    spacing = "default"
  } = _b, props = __objRest(_b, [
    "children",
    "className",
    "sideOffset",
    "spacing"
  ]);
  const updatedChildren = addSpacingToChildren(children, spacing);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
    DropdownMenu.Content,
    __spreadProps(__spreadValues({
      className: "z-50",
      side: "right",
      sideOffset
    }, props), {
      children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
        MenuContent,
        {
          className: cn(dropdownSpacingVariants({ spacing }), className),
          spacing,
          children: updatedChildren ? updatedChildren : null
        }
      )
    })
  );
}
Content4.displayName = DropdownMenu.Content.displayName;
function SubContent2(_a) {
  var _b = _a, {
    children,
    className,
    sideOffset = 0,
    spacing = "default"
  } = _b, props = __objRest(_b, [
    "children",
    "className",
    "sideOffset",
    "spacing"
  ]);
  const updatedChildren = addSpacingToChildren(children, spacing);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
    DropdownMenu.SubContent,
    __spreadProps(__spreadValues({
      className: "z-50",
      sideOffset
    }, props), {
      children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
        MenuContent,
        {
          className: cn(dropdownSpacingVariants({ spacing }), className),
          spacing,
          children: updatedChildren ? updatedChildren : null
        }
      )
    })
  );
}
SubContent2.displayName = DropdownMenu.SubContent.displayName;
function Sub2(_a) {
  var _b = _a, {
    children,
    spacing = "default"
  } = _b, props = __objRest(_b, [
    "children",
    "spacing"
  ]);
  const updatedChildren = addSpacingToChildren(children, spacing);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.Sub, __spreadProps(__spreadValues({}, props), { children: updatedChildren ? updatedChildren : null }));
}
Sub2.displayName = "DropdownMenuSub";
function Root5(_a) {
  var _b = _a, {
    children,
    spacing = "default"
  } = _b, props = __objRest(_b, [
    "children",
    "spacing"
  ]);
  const updatedChildren = addSpacingToChildren(children, spacing);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(DropdownMenu.Root, __spreadProps(__spreadValues({}, props), { children: updatedChildren ? updatedChildren : null }));
}
Root5.displayName = DropdownMenu.Root.displayName;

// src/checkbox/checkbox.tsx
var React9 = __toESM(require("react"));
var CheckboxPrimitive = __toESM(require("@radix-ui/react-checkbox"));
var import_lucide_react3 = require("lucide-react");
var import_jsx_runtime11 = require("react/jsx-runtime");
var Checkbox = React9.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  const IndicatorIcon = (() => {
    const checked = props.checked;
    if (checked === "indeterminate") {
      return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(import_lucide_react3.MinusIcon, { size: 14 });
    }
    return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(import_lucide_react3.Check, { size: 14 });
  })();
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
    CheckboxPrimitive.Root,
    __spreadProps(__spreadValues({
      className: cn(
        "focus-outline peer h-4 w-4 shrink-0 rounded border border-gray-500 hover:border-blue-500 hover:bg-blue-500/10 active:bg-blue-500/20 disabled:cursor-not-allowed disabled:opacity-50 peer-hover:border-blue-500 peer-hover:bg-blue-500/10 peer-active:bg-blue-500/20 data-[state=checked]:border-blue-500 data-[state=checked]:bg-blue-500 data-[state=checked]:text-white data-[state=checked]:active:border-blue-700 data-[state=checked]:active:bg-blue-700 data-[state=checked]:peer-active:border-blue-700 data-[state=checked]:peer-active:bg-blue-700",
        className
      ),
      ref
    }, props), {
      children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
        CheckboxPrimitive.Indicator,
        {
          className: cn("flex items-center justify-center text-current"),
          children: IndicatorIcon
        }
      )
    })
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

// src/group/group.tsx
var React10 = __toESM(require("react"));
var import_jsx_runtime12 = require("react/jsx-runtime");
function Group3(_a) {
  var _b = _a, {
    align = "start",
    direction = "horizontal",
    fill = false,
    gap = "md",
    inline = false,
    children
  } = _b, props = __objRest(_b, [
    "align",
    "direction",
    "fill",
    "gap",
    "inline",
    "children"
  ]);
  const _a2 = props, { className } = _a2, rest = __objRest(_a2, ["className"]);
  const updatedChildren = React10.Children.map(children, (child) => {
    if (React10.isValidElement(child)) {
      const childProps = child.props;
      const childClassName = "classname" in childProps ? childProps.className : "";
      const newItemProps = {
        className: fill ? `${childClassName != null ? childClassName : ""} flex-1` : childClassName
      };
      return React10.cloneElement(child, newItemProps);
    }
    return child;
  });
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
    "div",
    __spreadProps(__spreadValues({
      className: [
        inline ? "inline-flex" : "flex",
        direction === "vertical" ? "flex-col" : "flex-row",
        "items-center",
        {
          start: "justify-start",
          center: "justify-center",
          end: "justify-end",
          between: "justify-between"
        }[align],
        {
          none: "gap-x-0",
          px: "gap-x-px",
          xxs: "gap-x-0.5",
          xs: "gap-x-1",
          sm: "gap-x-2",
          md: "gap-x-4",
          lg: "gap-x-8"
        }[gap],
        // 'w-full',
        "max-w-full",
        className
      ].join(" ")
    }, rest), {
      children: updatedChildren
    })
  );
}

// src/hover-card/hover-card.tsx
var RadixHoverCard = __toESM(require("@radix-ui/react-hover-card"));
var import_jsx_runtime13 = require("react/jsx-runtime");
function HoverCard({
  content,
  trigger
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(RadixHoverCard.Root, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(RadixHoverCard.Trigger, { children: trigger }),
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(RadixHoverCard.Content, { className: "rounded-md border border-gray-200 bg-white p-4 text-center text-sm text-white", children: content })
  ] });
}

// src/input/input.tsx
var React11 = __toESM(require("react"));
var import_class_variance_authority5 = require("class-variance-authority");
var import_lucide_react4 = require("lucide-react");
var import_jsx_runtime14 = require("react/jsx-runtime");
var inputVariants = (0, import_class_variance_authority5.cva)(
  "focus focus-outline z-10 flex w-full truncate rounded-md border text-sm transition duration-200 ease-in-out hover:border-gray-400 focus:border-blue-400 focus:bg-white hover:focus:bg-white disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 dark:placeholder:text-gray-500 dark:hover:border-white/25 dark:focus:border-blue-600 dark:focus:bg-gray-900/20 dark:focus:hover:border-blue-500 dark:hover:focus:bg-gray-900/20",
  {
    variants: {
      inputSize: {
        xs: "h-6 py-0 pl-0.5 pr-0.5",
        sm: "h-7 py-1 pl-1 pr-1",
        md: "h-8 py-1.5 pl-2 pr-2",
        lg: "h-9 py-2 pl-2.5 pr-2.5"
      },
      type: {
        password: "pr-9"
      },
      variant: {
        default: "border-gray-950/10 dark:border-white/10 dark:bg-white/5",
        ghost: "border-transparent hover:border-transparent hover:bg-gray-100 focus:border-transparent hover:focus:border-transparent dark:hover:border-transparent dark:hover:bg-gray-600/30 dark:focus:border-transparent dark:hover:focus:border-transparent"
      }
    },
    defaultVariants: {
      inputSize: "md",
      variant: "default"
    }
  }
);
var Input = React11.forwardRef(
  (_a, ref) => {
    var _b = _a, { className, inputSize, type, variableWidth, variant } = _b, rest = __objRest(_b, ["className", "inputSize", "type", "variableWidth", "variant"]);
    const [showPassword, setShowPassword] = React11.useState(false);
    const inputType = React11.useMemo(() => {
      return type === "password" && showPassword ? "text" : type;
    }, [type, showPassword]);
    const ShowPasswordIcon = React11.useMemo(() => {
      if (type !== "password") {
        return null;
      }
      const icon = showPassword ? /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_lucide_react4.EyeOffIcon, {}) : /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_lucide_react4.EyeIcon, {});
      return /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("div", { className: "absolute right-1 z-20 flex h-full flex-col items-center justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
        IconButton,
        {
          className: cn(
            !showPassword ? "text-gray-300 dark:text-gray-500" : "",
            "focus-within:text-inherit"
          ),
          icon,
          label: showPassword ? "Hide Password" : "Show Password",
          onClick: () => {
            setShowPassword(!showPassword);
          },
          showTooltip: false,
          size: "sm",
          variant: "ghost"
        }
      ) });
    }, [type, showPassword]);
    const InputComponent = /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      "input",
      __spreadValues({
        className: cn(
          inputVariants({
            variant: variant != null ? variant : "default",
            inputSize: inputSize != null ? inputSize : "md",
            type: type === "password" ? "password" : void 0
          }),
          variableWidth ? "col-start-1 row-start-1 min-w-0" : "",
          className
        ),
        onInput: (e) => {
          if (rest.onInput) {
            rest.onInput(e);
          }
        },
        ref,
        type: inputType
      }, rest)
    );
    const PasswordComponent = /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)("div", { className: "relative flex items-center", children: [
      InputComponent,
      ShowPasswordIcon
    ] });
    const VariableWidthInputComponent = /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      "div",
      {
        className: cn(
          {
            xs: "after:h-6 after:py-0 after:pl-0.5 after:pr-0.5",
            sm: "after:h-7 after:py-1 after:pl-1 after:pr-1",
            md: "after:h-8 after:py-1.5 after:pl-2 after:pr-2",
            lg: "after:h-9 after:py-2 after:pl-2.5 after:pr-2.5"
          }[inputSize != null ? inputSize : "md"],
          "relative inline-grid items-center after:invisible after:col-start-1 after:row-start-1 after:flex after:truncate after:whitespace-pre-wrap after:border after:border-transparent after:text-sm after:content-[attr(data-value)]"
        ),
        "data-value": rest.value,
        children: InputComponent
      }
    );
    if (type === "password") {
      return PasswordComponent;
    }
    if (variableWidth) {
      return VariableWidthInputComponent;
    }
    return InputComponent;
  }
);
Input.displayName = "Input";

// src/input/search-input.tsx
var React12 = __toESM(require("react"));
var import_lucide_react5 = require("lucide-react");
var import_jsx_runtime15 = require("react/jsx-runtime");
var SearchInput = React12.forwardRef(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    return /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)("div", { className: "relative flex w-full items-center", children: [
      /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_lucide_react5.SearchIcon, { className: "absolute left-2 z-20 h-4 w-4 text-gray-400 dark:text-gray-600" }),
      /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
        Input,
        __spreadValues({
          className: cn("flex-1 pl-7", className),
          ref,
          type: "text"
        }, props)
      )
    ] });
  }
);
SearchInput.displayName = "SearchInput";

// src/label/label.tsx
var React13 = __toESM(require("react"));
var LabelPrimitive = __toESM(require("@radix-ui/react-label"));
var import_class_variance_authority6 = require("class-variance-authority");
var import_jsx_runtime16 = require("react/jsx-runtime");
var labelVariants = (0, import_class_variance_authority6.cva)(
  "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);
var Label3 = React13.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
    LabelPrimitive.Root,
    __spreadValues({
      className: cn(labelVariants(), className),
      ref
    }, props)
  );
});
Label3.displayName = LabelPrimitive.Root.displayName;

// src/popover/index.tsx
var popover_exports = {};
__export(popover_exports, {
  Close: () => Close2,
  Content: () => Content7,
  Label: () => Label4,
  Portal: () => Portal2,
  Root: () => Root11,
  Separator: () => Separator3,
  Trigger: () => Trigger6
});

// src/popover/popover.tsx
var PopoverPrimitive = __toESM(require("@radix-ui/react-popover"));
var RadixSeparator = __toESM(require("@radix-ui/react-separator"));
var import_framer_motion2 = require("framer-motion");
var React14 = __toESM(require("react"));
var import_class_variance_authority7 = require("class-variance-authority");
var import_jsx_runtime17 = require("react/jsx-runtime");
var Separator3 = React14.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
    RadixSeparator.Root,
    __spreadProps(__spreadValues({}, props), {
      className: cn("my-1.5 h-px bg-gray-200 dark:bg-gray-600", className),
      ref
    })
  );
});
Separator3.displayName = "Separator";
function Label4({
  children,
  size = "sm"
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
    "div",
    {
      className: [
        "px-2 text-left text-gray-600 dark:text-gray-400",
        {
          sm: "py-2 text-xs font-normal",
          md: "text-sm font-medium",
          lg: "text-md py-1 font-semibold"
        }[size]
      ].join(" "),
      children: children ? children : null
    }
  );
}
Label4.displayName = "Label";
var Root11 = React14.forwardRef((props, ref) => {
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(PopoverPrimitive.Root, __spreadProps(__spreadValues(__spreadValues({}, props), ref), { children: props.children }));
});
Root11.displayName = "Root";
var Trigger6 = React14.forwardRef((props, ref) => {
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(PopoverPrimitive.Trigger, __spreadProps(__spreadValues(__spreadValues({}, props), ref), { children: props.children }));
});
Trigger6.displayName = "Trigger";
var Content7 = React14.forwardRef((_a, ref) => {
  var _b = _a, { className, initialAnimation = true, size = "md" } = _b, props = __objRest(_b, ["className", "initialAnimation", "size"]);
  const initial = React14.useMemo(() => {
    if (!initialAnimation) {
      return false;
    }
    return { opacity: 0, y: "-0.25rem" };
  }, [initialAnimation]);
  const popoverContentVariants = (0, import_class_variance_authority7.cva)(
    "z-50 flex min-w-[12rem] flex-col rounded-lg border border-gray-950/5 bg-white p-4 shadow-xl focus:outline-none dark:border-gray-800 dark:bg-gray-700",
    {
      variants: {
        size: { sm: "gap-1 p-2", md: "gap-2 p-4", lg: "gap-3 p-6" }
      }
    }
  );
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
    PopoverPrimitive.Content,
    __spreadProps(__spreadValues(__spreadValues({
      asChild: true,
      className: cn(popoverContentVariants({ size }), className),
      side: "right"
    }, props), ref), {
      children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
        import_framer_motion2.motion.div,
        {
          animate: {
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.2,
              ease: "easeOut"
            }
          },
          exit: {
            opacity: 0,
            y: "-0.25rem",
            transition: {
              duration: 0.1,
              ease: "easeIn"
            }
          },
          initial,
          children: props.children ? props.children : null
        }
      )
    })
  );
});
Content7.displayName = "Content";
var Portal2 = PopoverPrimitive.Portal;
var Close2 = PopoverPrimitive.Close;

// src/radio-group/index.tsx
var radio_group_exports = {};
__export(radio_group_exports, {
  Indicator: () => Indicator3,
  Item: () => Item5,
  Root: () => Root13
});

// src/radio-group/radio-group.tsx
var import_react_label = require("@radix-ui/react-label");
var RadioGroupPrimitive = __toESM(require("@radix-ui/react-radio-group"));
var React15 = __toESM(require("react"));
var import_class_variance_authority8 = require("class-variance-authority");
var import_jsx_runtime18 = require("react/jsx-runtime");
var Root13 = React15.forwardRef((props, ref) => {
  const _a = props, { children, className, size = "md", value } = _a, rest = __objRest(_a, ["children", "className", "size", "value"]);
  const updatedChildren = React15.Children.map(children, (child) => {
    const radioItem = child;
    const type = radioItem.type;
    if (React15.isValidElement(radioItem) && type.displayName === "RadioGroupItem") {
      const newRadioItemProps = __spreadValues({
        selected: value
      }, radioItem.props);
      return React15.cloneElement(radioItem, newRadioItemProps);
    }
    return child;
  });
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
    RadioGroupPrimitive.Root,
    __spreadProps(__spreadValues(__spreadValues({
      className: cn(
        "flex flex-col",
        {
          sm: "text-sm",
          md: "",
          lg: "text-lg"
        }[size],
        className
      ),
      value
    }, rest), ref), {
      children: updatedChildren
    })
  );
});
Root13.displayName = "Root";
var Item5 = React15.forwardRef((props, ref) => {
  const { children, className, label, selected, size = "md", value } = props;
  const itemVariants = (0, import_class_variance_authority8.cva)(
    "focus-outline peer rounded-full border border-gray-500 bg-gray-300/10 hover:border-blue-400 hover:bg-blue-500/10 active:bg-blue-500/20 data-[state=checked]:border-blue-500 data-[state=checked]:bg-blue-500/10 dark:bg-gray-700/10 dark:data-[state=checked]:border-blue-400",
    {
      variants: {
        size: {
          sm: "h-3 w-3",
          md: "h-4 w-4",
          lg: "h-5 w-5"
        }
      }
    }
  );
  const indicatorVariants = (0, import_class_variance_authority8.cva)(
    `relative flex h-full w-full items-center justify-center after:block after:rounded-full after:bg-blue-500 after:content-[''] dark:after:bg-blue-400`,
    {
      variants: {
        size: {
          sm: "after:h-2 after:w-2 after:outline-offset-1",
          md: "after:h-2 after:w-2 after:outline-offset-4",
          lg: "after:h-4 after:w-4 after:outline-offset-4"
        }
      }
    }
  );
  const item = /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
    RadioGroupPrimitive.Item,
    __spreadProps(__spreadValues(__spreadValues({
      className: cn(className, itemVariants({ size }))
    }, props), ref), {
      children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(RadioGroupPrimitive.Indicator, { className: indicatorVariants({ size }) })
    })
  );
  return label ? /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_jsx_runtime18.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(
      import_react_label.Label,
      {
        className: cn(
          "flex cursor-pointer items-center justify-start gap-2 py-1",
          {
            sm: "text-sm",
            md: "",
            lg: "text-lg"
          }[size]
        ),
        children: [
          item,
          /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
            "div",
            {
              className: cn(
                "peer text-gray-700 hover:text-gray-800 peer-data-[state=checked]:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 dark:peer-data-[state=checked]:text-gray-300",
                selected === value ? "text-gray-800 dark:text-gray-300" : ""
              ),
              children: label
            }
          )
        ]
      }
    ),
    children
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_jsx_runtime18.Fragment, { children: [
    item,
    children
  ] });
});
Item5.displayName = "Item";
var Indicator3 = React15.forwardRef((props, ref) => {
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(RadioGroupPrimitive.Indicator, __spreadProps(__spreadValues(__spreadValues({}, props), ref), { children: props.children }));
});
Indicator3.displayName = "Indicator";

// src/select/index.tsx
var select_exports = {};
__export(select_exports, {
  Content: () => Content9,
  Group: () => Group5,
  Item: () => Item7,
  Label: () => Label7,
  Portal: () => Portal4,
  Root: () => Root15,
  Separator: () => Separator5,
  Trigger: () => Trigger8,
  Value: () => Value2
});

// src/select/select.tsx
var SelectPrimitive6 = __toESM(require("@radix-ui/react-select"));

// src/select/label.tsx
var SelectPrimitive = __toESM(require("@radix-ui/react-select"));
var React16 = __toESM(require("react"));
var import_jsx_runtime19 = require("react/jsx-runtime");
var Label7 = React16.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
    SelectPrimitive.Label,
    __spreadValues({
      className: cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className),
      ref
    }, props)
  );
});
Label7.displayName = SelectPrimitive.Label.displayName;

// src/select/content.tsx
var import_lucide_react6 = require("lucide-react");
var SelectPrimitive2 = __toESM(require("@radix-ui/react-select"));
var import_framer_motion3 = require("framer-motion");
var React17 = __toESM(require("react"));
var import_jsx_runtime20 = require("react/jsx-runtime");
var Content9 = React17.forwardRef((_a, forwardedRef) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return (
    // <SelectPrimitive.Portal>
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      SelectPrimitive2.SelectContent,
      __spreadProps(__spreadValues({
        className: cn(props.className, "z-[60]")
      }, props), {
        ref: forwardedRef,
        children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
          import_framer_motion3.motion.div,
          {
            animate: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.2,
                ease: "easeOut"
              }
            },
            className: "flex min-w-full flex-col overflow-hidden whitespace-nowrap rounded-lg border border-gray-200 bg-white p-1.5 text-sm text-gray-700 shadow-xl focus:outline-none dark:border-gray-800 dark:bg-gray-700 dark:text-gray-400",
            exit: {
              opacity: 0,
              y: "-0.25rem",
              transition: {
                duration: 0.1,
                ease: "easeIn"
              }
            },
            initial: { opacity: 0, y: "-0.75rem" },
            children: /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(SelectPrimitive2.Viewport, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(SelectPrimitive2.ScrollUpButton, { className: "flex items-center justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_lucide_react6.ChevronUpIcon, { className: "h-4 w-4" }) }),
              children ? children : null,
              /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(SelectPrimitive2.ScrollDownButton, { className: "flex items-center justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_lucide_react6.ChevronDownIcon, { className: "h-4 w-4" }) })
            ] })
          }
        )
      })
    )
  );
});
Content9.displayName = SelectPrimitive2.Content.displayName;

// src/select/item.tsx
var import_lucide_react7 = require("lucide-react");
var SelectPrimitive3 = __toESM(require("@radix-ui/react-select"));
var React18 = __toESM(require("react"));
var import_jsx_runtime21 = require("react/jsx-runtime");
var Item7 = React18.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, { children, className, size = "md" } = _b, props = __objRest(_b, ["children", "className", "size"]);
    return /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)(
      SelectPrimitive3.SelectItem,
      __spreadProps(__spreadValues({
        className: [
          className,
          {
            xs: "py-1 pl-1 pr-1.5",
            sm: "py-1 pl-1 pr-1.5",
            md: "py-1.5 pl-1 pr-2"
          }[size],
          "relative flex cursor-pointer select-none items-center justify-between rounded-md text-gray-600 focus:outline-none dark:text-gray-400",
          "[&[data-highlighted]]:bg-gray-100 [&[data-highlighted]]:text-gray-900 dark:[&[data-highlighted]]:bg-gray-600 dark:[&[data-highlighted]]:text-gray-300",
          "data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
        ].join(" ")
      }, props), {
        ref: forwardedRef,
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(SelectPrimitive3.ItemText, { children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("span", { className: "flex", children }) }),
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(SelectPrimitive3.ItemIndicator, { children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_lucide_react7.CheckIcon, { size: 16 }) })
        ]
      })
    );
  }
);
Item7.displayName = SelectPrimitive3.Item.displayName;

// src/select/trigger.tsx
var SelectPrimitive4 = __toESM(require("@radix-ui/react-select"));
var React19 = __toESM(require("react"));
var import_class_variance_authority9 = require("class-variance-authority");
var import_lucide_react8 = require("lucide-react");
var import_jsx_runtime22 = require("react/jsx-runtime");
var selectTriggerVariants = (0, import_class_variance_authority9.cva)(
  "focus-outline inline-flex items-center justify-between gap-x-1 rounded-md border tabular-nums text-gray-800 ring ring-transparent transition duration-200 ease-in-out selection:relative disabled:cursor-not-allowed disabled:opacity-50 dark:text-gray-300",
  {
    variants: {
      size: {
        xs: "h-5 px-0 py-0.5 text-xs",
        sm: "h-7 py-1 pl-2 pr-1.5 text-sm",
        md: "h-8 px-2 py-1.5 text-sm"
      },
      // state: {
      //   enabled: '',
      //   disabled: 'pointer-events-none [&[data-disabled]]:opacity-70',
      //   loading: 'pointer-events-none',
      // },
      variant: {
        default: "border-gray-300 bg-white hover:border-gray-400 focus-visible:border-blue-400 active:border-blue-400 disabled:hover:border-gray-300 dark:border-gray-700 dark:bg-gray-800 dark:hover:border-gray-600 dark:focus-visible:border-blue-600 dark:disabled:hover:border-gray-700",
        ghost: "border-transparent bg-transparent hover:bg-gray-100 disabled:border-transparent dark:hover:bg-gray-600/30"
      }
    },
    defaultVariants: {
      size: "md",
      // state: 'enabled',
      variant: "default"
    }
  }
);
var Trigger8 = React19.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      children,
      className,
      icon,
      iconSide = "right",
      label,
      showIcon = true,
      size = "md",
      variant = "default"
    } = _b, props = __objRest(_b, [
      "children",
      "className",
      "icon",
      "iconSide",
      "label",
      "showIcon",
      "size",
      "variant"
    ]);
    const selectIcon = React19.useMemo(() => {
      if (icon) {
        return icon;
      }
      return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(import_lucide_react8.ChevronDownIcon, { className: "opacity-60", size: 16 });
    }, [icon]);
    return /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(
      SelectPrimitive4.SelectTrigger,
      __spreadProps(__spreadValues({
        className: cn(selectTriggerVariants({ size, variant }), className)
      }, props), {
        ref: forwardedRef,
        children: [
          Boolean(iconSide === "left" && showIcon) && /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(SelectPrimitive4.SelectIcon, { children: selectIcon }),
          Boolean(label) && /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)("div", { className: "flex gap-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime22.jsx)("div", { className: "font-medium text-gray-400 dark:text-gray-500", children: label }),
            /* @__PURE__ */ (0, import_jsx_runtime22.jsx)("div", { className: "my-0 mr-1 w-px rounded-full bg-gray-300 dark:bg-gray-700" })
          ] }),
          children,
          Boolean(iconSide === "right" && showIcon) && /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(SelectPrimitive4.SelectIcon, { children: selectIcon })
        ]
      })
    );
  }
);
Trigger8.displayName = SelectPrimitive4.Trigger.displayName;

// src/select/separator.tsx
var SelectPrimitive5 = __toESM(require("@radix-ui/react-select"));
var React20 = __toESM(require("react"));
var import_jsx_runtime23 = require("react/jsx-runtime");
var Separator5 = React20.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
    SelectPrimitive5.Separator,
    __spreadValues({
      className: cn("-mx-1 my-1 h-px bg-gray-100 dark:bg-gray-900", className),
      ref
    }, props)
  );
});
Separator5.displayName = SelectPrimitive5.Separator.displayName;

// src/select/select.tsx
var Group5 = SelectPrimitive6.Group;
var Portal4 = SelectPrimitive6.Portal;
var Root15 = SelectPrimitive6.Root;
var Value2 = SelectPrimitive6.Value;

// src/selector/selector.tsx
var import_lucide_react11 = require("lucide-react");
var import_react_label2 = require("@radix-ui/react-label");
var SelectPrimitive12 = __toESM(require("@radix-ui/react-select"));

// src/selector/content.tsx
var SelectPrimitive7 = __toESM(require("@radix-ui/react-select"));
var import_framer_motion4 = require("framer-motion");
var React21 = __toESM(require("react"));
var import_jsx_runtime24 = require("react/jsx-runtime");
var Content11 = React21.forwardRef((_a, forwardedRef) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(SelectPrimitive7.SelectContent, __spreadProps(__spreadValues({}, props), { ref: forwardedRef, children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
    import_framer_motion4.motion.div,
    {
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.2,
          ease: "easeOut"
        }
      },
      className: "flex min-w-full flex-col overflow-hidden whitespace-nowrap rounded-lg border border-gray-200 bg-white p-1.5 text-sm text-gray-700 shadow-xl focus:outline-none dark:border-gray-800 dark:bg-gray-700 dark:text-gray-400",
      exit: {
        opacity: 0,
        y: "-0.25rem",
        transition: {
          duration: 0.1,
          ease: "easeIn"
        }
      },
      initial: { opacity: 0, y: "-0.75rem" },
      children: children ? children : null
    }
  ) }));
});
Content11.displayName = SelectPrimitive7.Content.displayName;

// src/selector/icon.tsx
var SelectPrimitive8 = __toESM(require("@radix-ui/react-select"));
var import_lucide_react9 = require("lucide-react");
var import_jsx_runtime25 = require("react/jsx-runtime");
function Icon() {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(SelectPrimitive8.SelectIcon, { className: [].join(" "), children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_lucide_react9.ChevronDownIcon, { size: 20 }) });
}
Icon.displayName = SelectPrimitive8.SelectIcon.displayName;

// src/selector/item.tsx
var import_lucide_react10 = require("lucide-react");
var SelectPrimitive9 = __toESM(require("@radix-ui/react-select"));
var React22 = __toESM(require("react"));
var import_jsx_runtime26 = require("react/jsx-runtime");
var Item9 = React22.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, { className, option, size, state } = _b, props = __objRest(_b, ["className", "option", "size", "state"]);
    return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(
      SelectPrimitive9.SelectItem,
      __spreadProps(__spreadValues({
        className: [
          className,
          {
            xs: "py-1 pl-1 pr-1.5",
            sm: "py-1 pl-1 pr-1.5",
            md: "py-1.5 pl-1 pr-2"
          }[size],
          "relative flex cursor-pointer select-none items-center justify-between rounded-md text-gray-600 focus:outline-none dark:text-gray-400",
          {
            enabled: "[&[data-highlighted]]:bg-gray-100 [&[data-highlighted]]:text-gray-900 dark:[&[data-highlighted]]:bg-gray-600 dark:[&[data-highlighted]]:text-gray-300",
            loading: "",
            disabled: ""
          }[state]
        ].join(" "),
        disabled: {
          enabled: false,
          disabled: true,
          loading: true
        }[state]
      }, props), {
        ref: forwardedRef,
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(SelectPrimitive9.ItemText, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("span", { className: "flex px-1", children: option.value }) }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(SelectPrimitive9.ItemIndicator, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_lucide_react10.CheckIcon, { className: "h-4 w-4" }) })
        ]
      })
    );
  }
);
Item9.displayName = SelectPrimitive9.Item.displayName;

// src/selector/trigger.tsx
var SelectPrimitive10 = __toESM(require("@radix-ui/react-select"));
var React23 = __toESM(require("react"));
var import_class_variance_authority10 = require("class-variance-authority");
var import_jsx_runtime27 = require("react/jsx-runtime");
var selectTriggerVariants2 = (0, import_class_variance_authority10.cva)(
  "gap-x focus-outline inline-flex items-center justify-between whitespace-nowrap rounded-md border text-sm tabular-nums text-gray-800 ring ring-transparent transition duration-200 ease-in-out selection:relative focus:z-10",
  {
    variants: {
      label: {
        true: "dark:text-gray-300",
        false: "dark:text-gray-400"
      },
      size: {
        xs: "h-7 py-1 pl-1 pr-1",
        sm: "h-7 py-1 pl-2 pr-1.5",
        md: "h-8 px-2 py-1.5"
      },
      state: {
        enabled: "",
        disabled: "pointer-events-none [&[data-disabled]]:opacity-70",
        loading: "pointer-events-none"
      },
      variant: {
        default: "border-gray-300 bg-white hover:border-gray-400 focus-visible:border-blue-400 active:border-blue-400 dark:border-gray-700 dark:bg-gray-800 dark:hover:border-gray-600 dark:focus-visible:border-blue-600",
        ghost: "border-transparent bg-transparent hover:bg-gray-100 dark:hover:bg-gray-600/30"
      }
    },
    defaultVariants: {
      size: "md",
      state: "enabled",
      variant: "default"
    }
  }
);
var Trigger9 = React23.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      children,
      className,
      label,
      size = "md",
      state = "enabled",
      variant = "default"
    } = _b, props = __objRest(_b, [
      "children",
      "className",
      "label",
      "size",
      "state",
      "variant"
    ]);
    return /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
      SelectPrimitive10.SelectTrigger,
      __spreadProps(__spreadValues({
        className: cn(
          selectTriggerVariants2({
            label: Boolean(label),
            size,
            state,
            variant
          }),
          className
        )
      }, props), {
        ref: forwardedRef,
        children: [
          label ? /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex gap-2", children: [
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: "font-medium text-gray-400 dark:text-gray-400", children: label }),
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: "my-0 mr-1 w-px rounded-full bg-gray-300 dark:bg-gray-700" })
          ] }) : null,
          children ? children : null
        ]
      })
    );
  }
);
Trigger9.displayName = SelectPrimitive10.SelectTrigger.displayName;

// src/selector/viewport.tsx
var SelectPrimitive11 = __toESM(require("@radix-ui/react-select"));
var React24 = __toESM(require("react"));
var import_jsx_runtime28 = require("react/jsx-runtime");
var Viewport2 = React24.forwardRef(({ children }, forwardedRef) => {
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(SelectPrimitive11.SelectViewport, { className: [].join(" "), ref: forwardedRef, children: children ? children : null });
});
Viewport2.displayName = "Viewport";

// src/selector/selector.tsx
var import_jsx_runtime29 = require("react/jsx-runtime");
function Selector(_a) {
  var _b = _a, {
    className,
    inlineLabel,
    label,
    onChange,
    options,
    placeholder = "",
    selectedOption,
    showIndicator = true,
    size = "md",
    state = "enabled",
    variant = "default"
  } = _b, rest = __objRest(_b, [
    "className",
    "inlineLabel",
    "label",
    "onChange",
    "options",
    "placeholder",
    "selectedOption",
    "showIndicator",
    "size",
    "state",
    "variant"
  ]);
  const styledOptions = {
    enabled: options.map((option) => {
      return /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
        Item9,
        {
          option,
          size,
          state,
          value: option.key.toString()
        },
        option.key.toString()
      );
    }),
    disabled: null,
    loading: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
      Item9,
      {
        option: {
          key: "loading",
          value: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)("div", { className: "flex flex-col", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(LoadingIcon, { size: "md" }) })
        },
        size,
        state,
        value: "loading"
      }
    )
  }[state];
  const selector = /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(
    SelectPrimitive12.Root,
    __spreadProps(__spreadValues({
      defaultValue: selectedOption == null ? void 0 : selectedOption.key.toString(),
      disabled: state !== "enabled",
      onValueChange: (value) => {
        const option = options.find(
          (newOption) => newOption.key.toString() === value
        );
        onChange(option);
      },
      value: selectedOption == null ? void 0 : selectedOption.key.toString()
    }, rest), {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(
          Trigger9,
          {
            className,
            label: inlineLabel ? label : void 0,
            size,
            state,
            variant,
            children: [
              state === "loading" ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)("div", { className: "pr-2", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(LoadingIcon, {}) }) : /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(SelectPrimitive12.Value, { placeholder }),
              showIndicator ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Icon, {}) : null
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(Content11, { className: "z-50", children: [
          /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(SelectPrimitive12.ScrollUpButton, { className: "flex items-center justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_lucide_react11.ChevronUpIcon, { size: 16 }) }),
          /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Viewport2, { children: /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(SelectPrimitive12.Group, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(SelectPrimitive12.Label, {}),
            styledOptions
          ] }) }),
          /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(SelectPrimitive12.ScrollDownButton, { className: "flex items-center justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_lucide_react11.ChevronDownIcon, { size: 16 }) })
        ] })
      ]
    })
  );
  return !inlineLabel && label ? /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_react_label2.Label, { className: "flex flex-col items-start justify-center text-sm text-gray-500 dark:text-gray-400", children: [
    label,
    selector
  ] }) : selector;
}

// src/side-dialog/side-dialog.tsx
var import_lucide_react12 = require("lucide-react");
var Dialog = __toESM(require("@radix-ui/react-dialog"));
var import_jsx_runtime30 = require("react/jsx-runtime");
function SideDialog(_a) {
  var _b = _a, {
    content,
    description,
    formId,
    onClickSave,
    size = "default",
    title,
    trigger
  } = _b, props = __objRest(_b, [
    "content",
    "description",
    "formId",
    "onClickSave",
    "size",
    "title",
    "trigger"
  ]);
  if (!content) {
    return null;
  }
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(Dialog.Root, __spreadProps(__spreadValues({}, props), { children: [
    trigger ? /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Dialog.Trigger, { asChild: true, children: trigger }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(Dialog.Portal, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
        Dialog.Overlay,
        {
          className: [
            "fixed inset-0 z-50 bg-gray-700/20 dark:bg-gray-700/50"
          ].join(" ")
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(
        Dialog.Content,
        {
          className: [
            "fixed right-0 top-0 z-50 grid h-screen min-h-screen  grid-rows-[auto_1fr_auto] overflow-y-hidden border-l border-gray-200 bg-white shadow-lg focus:outline-none dark:border-gray-800 dark:bg-gray-950",
            {
              small: "min-w-[300px] max-w-[300px]",
              default: "min-w-[400px]",
              large: "w-screen sm:w-[600px] sm:min-w-[600px] sm:max-w-[600px]"
            }[size]
          ].join(" "),
          onEscapeKeyDown: (e) => {
            e.preventDefault();
          },
          onInteractOutside: (e) => {
            e.preventDefault();
          },
          onPointerDownOutside: (e) => {
            e.preventDefault();
          },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)("div", { className: "p-6", children: [
              title ? /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)("div", { className: "flex items-center justify-between", children: [
                /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
                  Dialog.Title,
                  {
                    className: [
                      {
                        small: "text-base font-medium",
                        default: "text-base font-medium",
                        large: "text-2xl font-medium"
                      }[size]
                    ].join(" "),
                    children: title
                  }
                ),
                /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Dialog.Close, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
                  IconButton,
                  {
                    icon: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_lucide_react12.XIcon, { size: 20 }),
                    label: "close",
                    showTooltip: false,
                    size: "md",
                    variant: "ghost"
                  }
                ) })
              ] }) : /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { className: "flex items-center justify-end", children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Dialog.Close, { children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
                Button,
                {
                  leftIcon: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_lucide_react12.XIcon, { size: 16 }),
                  size: "sm",
                  variant: "ghost"
                }
              ) }) }),
              description ? /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Dialog.Description, { className: "mt-2 text-sm text-gray-600", children: description }) : null
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { className: "h-full overflow-auto px-6", children: content }),
            /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(ButtonGroup, { className: "bg-gray-100 p-6 dark:bg-gray-900", size: "lg", children: [
              /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Dialog.Close, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Button, { variant: "default", children: "Cancel" }) }),
              /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
                Button,
                {
                  fill: true,
                  form: formId,
                  onClick: onClickSave,
                  type: "submit",
                  variant: "primary",
                  children: "Save Changes"
                }
              )
            ] })
          ]
        }
      )
    ] })
  ] }));
}

// src/switch/switch.tsx
var React25 = __toESM(require("react"));
var SwitchPrimitives = __toESM(require("@radix-ui/react-switch"));
var import_jsx_runtime31 = require("react/jsx-runtime");
var Switch = React25.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
    SwitchPrimitives.Root,
    __spreadProps(__spreadValues({
      className: cn(
        "focus-outline peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-gray-700 data-[state=unchecked]:bg-gray-200 dark:data-[state=checked]:bg-gray-200 dark:data-[state=unchecked]:bg-gray-700",
        className
      )
    }, props), {
      ref,
      children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
        SwitchPrimitives.Thumb,
        {
          className: cn(
            "pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 dark:bg-gray-950"
          )
        }
      )
    })
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

// src/text/text.tsx
var React26 = __toESM(require("react"));
var import_jsx_runtime32 = require("react/jsx-runtime");
function Text(props) {
  const _a = props, {
    children,
    className,
    overflow = "wrap",
    variant = "default"
  } = _a, rest = __objRest(_a, [
    "children",
    "className",
    "overflow",
    "variant"
  ]);
  const defaultElement = React26.useMemo(() => {
    return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
      "span",
      {
        className: cn(
          "block focus-within:inline-flex focus-within:min-w-full focus-within:bg-inherit hover:inline-flex hover:min-w-full hover:bg-inherit focus-visible:inline-flex focus-visible:min-w-full focus-visible:bg-inherit",
          {
            wrap: "",
            ellipsis: "overflow-hidden overflow-ellipsis whitespace-nowrap"
          }[overflow]
        ),
        children
      }
    );
  }, [children, overflow]);
  const codeElement = React26.useMemo(() => {
    return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
      "code",
      {
        className: "rounded bg-blue-500/5 px-1.5 py-1 text-xs text-blue-700 dark:bg-blue-300/10 dark:text-blue-300",
        id: "vpUrlExample",
        children
      }
    );
  }, [children]);
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)("div", __spreadProps(__spreadValues({ className: [className, "bg-inherit"].join(" ") }, rest), { children: {
    default: defaultElement,
    code: codeElement
  }[variant] }));
}

// src/textarea/textarea.tsx
var React27 = __toESM(require("react"));
var import_class_variance_authority11 = require("class-variance-authority");
var import_jsx_runtime33 = require("react/jsx-runtime");
var textareaVariants = (0, import_class_variance_authority11.cva)(
  "text-wrap focus focus-outline z-10 flex w-full truncate rounded-md border px-2 py-2 text-sm transition duration-200 ease-in-out hover:border-gray-400 focus:border-blue-400 focus:bg-white hover:focus:bg-white disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 dark:placeholder:text-gray-500 dark:hover:border-white/25 dark:focus:border-blue-600 dark:focus:bg-gray-900/20 dark:focus:hover:border-blue-500 dark:hover:focus:bg-gray-900/20",
  {
    variants: {
      variant: {
        default: "border-gray-950/10 dark:border-white/10 dark:bg-white/5",
        ghost: "border-transparent hover:border-transparent hover:bg-gray-100 focus:border-transparent hover:focus:border-transparent dark:hover:border-transparent dark:hover:bg-gray-600/30 dark:focus:border-transparent dark:hover:focus:border-transparent"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);
var Textarea = React27.forwardRef(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    var _a2;
    return /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(
      "textarea",
      __spreadValues({
        className: cn(
          textareaVariants({
            variant: (_a2 = props.variant) != null ? _a2 : "default"
          }),
          className
        ),
        ref
      }, props)
    );
  }
);
Textarea.displayName = "Textarea";

// src/toast/toast.tsx
var React28 = __toESM(require("react"));
var ToastPrimitives = __toESM(require("@radix-ui/react-toast"));
var import_class_variance_authority12 = require("class-variance-authority");
var import_lucide_react13 = require("lucide-react");
var import_jsx_runtime34 = require("react/jsx-runtime");
var ToastProvider = ToastPrimitives.Provider;
var ToastViewport = React28.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
    ToastPrimitives.Viewport,
    __spreadValues({
      className: cn(
        "fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]",
        className
      ),
      ref
    }, props)
  );
});
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
var toastVariants = (0, import_class_variance_authority12.cva)(
  "group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        default: "border bg-white dark:border-gray-700 dark:bg-gray-950",
        destructive: "destructive border-destructive bg-destructive text-destructive-foreground group"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);
var Toast = React28.forwardRef((_a, ref) => {
  var _b = _a, { className, variant } = _b, props = __objRest(_b, ["className", "variant"]);
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
    ToastPrimitives.Root,
    __spreadValues({
      className: cn(toastVariants({ variant }), className),
      ref
    }, props)
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;
var ToastAction = React28.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
    ToastPrimitives.Action,
    __spreadValues({
      className: cn(
        "ring-offset-background hover:bg-secondary focus:ring-ring group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
        className
      ),
      ref
    }, props)
  );
});
ToastAction.displayName = ToastPrimitives.Action.displayName;
var ToastClose = React28.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
    ToastPrimitives.Close,
    __spreadProps(__spreadValues({
      className: cn(
        "text-foreground/50 hover:text-foreground absolute right-2 top-2 rounded-md p-1 opacity-0 transition-opacity focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
        className
      ),
      ref,
      "toast-close": ""
    }, props), {
      children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_lucide_react13.X, { className: "h-4 w-4" })
    })
  );
});
ToastClose.displayName = ToastPrimitives.Close.displayName;
var ToastTitle = React28.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
    ToastPrimitives.Title,
    __spreadValues({
      className: cn("text-sm font-semibold", className),
      ref
    }, props)
  );
});
ToastTitle.displayName = ToastPrimitives.Title.displayName;
var ToastDescription = React28.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
    ToastPrimitives.Description,
    __spreadValues({
      className: cn("text-sm opacity-90", className),
      ref
    }, props)
  );
});
ToastDescription.displayName = ToastPrimitives.Description.displayName;

// src/toast/use-toast.tsx
var React29 = __toESM(require("react"));
var TOAST_LIMIT = 1;
var TOAST_REMOVE_DELAY = 1e6;
var count = 0;
function genId() {
  count = (count + 1) % Number.MAX_VALUE;
  return count.toString();
}
var toastTimeouts = /* @__PURE__ */ new Map();
var addToRemoveQueue = (toastId) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: "REMOVE_TOAST",
      toastId
    });
  }, TOAST_REMOVE_DELAY);
  toastTimeouts.set(toastId, timeout);
};
var reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return __spreadProps(__spreadValues({}, state), {
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT)
      });
    case "UPDATE_TOAST":
      return __spreadProps(__spreadValues({}, state), {
        toasts: state.toasts.map(
          (t) => t.id === action.toast.id ? __spreadValues(__spreadValues({}, t), action.toast) : t
        )
      });
    case "DISMISS_TOAST": {
      const { toastId } = action;
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((stateToast) => {
          addToRemoveQueue(stateToast.id);
        });
      }
      return __spreadProps(__spreadValues({}, state), {
        toasts: state.toasts.map(
          (t) => t.id === toastId || toastId === void 0 ? __spreadProps(__spreadValues({}, t), {
            open: false
          }) : t
        )
      });
    }
    case "REMOVE_TOAST":
      if (action.toastId === void 0) {
        return __spreadProps(__spreadValues({}, state), {
          toasts: []
        });
      }
      return __spreadProps(__spreadValues({}, state), {
        toasts: state.toasts.filter((t) => t.id !== action.toastId)
      });
  }
};
var listeners = [];
var memoryState = { toasts: [] };
function dispatch(action) {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
}
function toast(_a) {
  var props = __objRest(_a, []);
  const id = genId();
  const update = (updateProps) => {
    dispatch({
      type: "UPDATE_TOAST",
      toast: __spreadProps(__spreadValues({}, updateProps), { id })
    });
  };
  const dismiss = () => {
    dispatch({ type: "DISMISS_TOAST", toastId: id });
  };
  dispatch({
    type: "ADD_TOAST",
    toast: __spreadProps(__spreadValues({}, props), {
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open)
          dismiss();
      }
    })
  });
  return {
    id,
    dismiss,
    update
  };
}
function useToast() {
  const [state, setState] = React29.useState(memoryState);
  React29.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);
  return __spreadProps(__spreadValues({}, state), {
    toast,
    dismiss: (toastId) => {
      dispatch({ type: "DISMISS_TOAST", toastId });
    }
  });
}

// src/toast/toaster.tsx
var import_jsx_runtime35 = require("react/jsx-runtime");
function Toaster() {
  const { toasts } = useToast();
  return /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(ToastProvider, { children: [
    toasts.map((_a) => {
      var _b = _a, { id, title, description, action } = _b, props = __objRest(_b, ["id", "title", "description", "action"]);
      return /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(Toast, __spreadProps(__spreadValues({}, props), { children: [
        /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)("div", { className: "grid gap-1", children: [
          title ? /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(ToastTitle, { children: title }) : null,
          description ? /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(ToastDescription, { children: description }) : null
        ] }),
        action,
        /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(ToastClose, {})
      ] }), id);
    }),
    /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(ToastViewport, {})
  ] });
}

// src/form/form.tsx
var import_react_hook_form = require("react-hook-form");
var Form = import_react_hook_form.FormProvider;

// src/form/form-control.tsx
var React32 = __toESM(require("react"));
var import_react_slot = require("@radix-ui/react-slot");

// src/form/form-field.tsx
var React31 = __toESM(require("react"));
var import_react_hook_form2 = require("react-hook-form");

// src/form/form-item.tsx
var React30 = __toESM(require("react"));
var import_jsx_runtime36 = require("react/jsx-runtime");
var FormItemContext = React30.createContext(
  {}
);
var FormItem = React30.forwardRef(function FormItem2(_a, ref) {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  const id = React30.useId();
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(FormItemContext.Provider, { value: { id }, children: /* @__PURE__ */ (0, import_jsx_runtime36.jsx)("div", __spreadValues({ className: cn("space-y-2", className), ref }, props)) });
});
FormItem.displayName = "FormItem";

// src/form/form-field.tsx
var import_jsx_runtime37 = require("react/jsx-runtime");
var FormFieldContext = React31.createContext(
  {}
);
function FormField(_a) {
  var props = __objRest(_a, []);
  return /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(FormFieldContext.Provider, { value: { name: props.name }, children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(import_react_hook_form2.Controller, __spreadValues({}, props)) });
}
FormField.displayName = "FormField";
function useFormField() {
  const fieldContext = React31.useContext(FormFieldContext);
  const itemContext = React31.useContext(FormItemContext);
  const { getFieldState, formState } = (0, import_react_hook_form2.useFormContext)();
  const fieldState = getFieldState(fieldContext.name, formState);
  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }
  const { id } = itemContext;
  return __spreadValues({
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`
  }, fieldState);
}

// src/form/form-control.tsx
var import_jsx_runtime38 = require("react/jsx-runtime");
var FormControl = React32.forwardRef((_a, ref) => {
  var props = __objRest(_a, []);
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();
  return /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(
    import_react_slot.Slot,
    __spreadValues({
      "aria-describedby": !error ? formDescriptionId : `${formDescriptionId} ${formMessageId}`,
      "aria-invalid": Boolean(error),
      id: formItemId,
      ref
    }, props)
  );
});
FormControl.displayName = "FormControl";

// src/form/form-description.tsx
var React33 = __toESM(require("react"));
var import_jsx_runtime39 = require("react/jsx-runtime");
var FormDescription = React33.forwardRef(function FormDescription2(_a, ref) {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  const { formDescriptionId } = useFormField();
  return /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
    "p",
    __spreadValues({
      className: cn("text-sm text-gray-500 dark:text-gray-400", className),
      id: formDescriptionId,
      ref
    }, props)
  );
});
FormDescription.displayName = "FormDescription";

// src/form/form-label.tsx
var React35 = __toESM(require("react"));

// src/form/label.tsx
var React34 = __toESM(require("react"));
var LabelPrimitive2 = __toESM(require("@radix-ui/react-label"));
var import_class_variance_authority13 = require("class-variance-authority");
var import_jsx_runtime40 = require("react/jsx-runtime");
var labelVariants2 = (0, import_class_variance_authority13.cva)(
  "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);
var Label10 = React34.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
    LabelPrimitive2.Root,
    __spreadValues({
      className: cn(labelVariants2(), className),
      ref
    }, props)
  );
});
Label10.displayName = LabelPrimitive2.Root.displayName;

// src/form/form-label.tsx
var import_jsx_runtime41 = require("react/jsx-runtime");
var FormLabel = React35.forwardRef((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  const { error, formItemId } = useFormField();
  return /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
    Label10,
    __spreadValues({
      className: cn(error && "text-rose-500 dark:text-rose-500", className),
      htmlFor: formItemId,
      ref
    }, props)
  );
});
FormLabel.displayName = "FormLabel";

// src/form/form-message.tsx
var React36 = __toESM(require("react"));
var import_jsx_runtime42 = require("react/jsx-runtime");
var FormMessage = React36.forwardRef((_a, ref) => {
  var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
  const { error, formMessageId } = useFormField();
  const body = error ? String(error.message) : children;
  if (!body) {
    return null;
  }
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
    "p",
    __spreadProps(__spreadValues({
      className: cn(
        "text-sm font-normal text-rose-500 dark:text-rose-500",
        className
      ),
      id: formMessageId,
      ref
    }, props), {
      children: body
    })
  );
});
FormMessage.displayName = "FormMessage";

// src/form/form-section.tsx
var Collapsible = __toESM(require("@radix-ui/react-collapsible"));
var import_lucide_react14 = require("lucide-react");
var React37 = __toESM(require("react"));
var import_jsx_runtime43 = require("react/jsx-runtime");
function FormSection(props) {
  const [open, setOpen] = React37.useState(true);
  const _a = props, { children, title } = _a, rest = __objRest(_a, ["children", "title"]);
  return /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(
    Collapsible.Root,
    __spreadProps(__spreadValues({
      className: "flex w-full flex-col",
      onOpenChange: setOpen,
      open
    }, rest), {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(Collapsible.Trigger, { className: "focus-outline my-1 flex w-full items-center justify-between rounded-lg bg-gray-100 px-4 py-2 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700", children: [
          /* @__PURE__ */ (0, import_jsx_runtime43.jsx)("span", { children: title }),
          /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(
            import_lucide_react14.ChevronDownIcon,
            {
              className: "h-5 w-5",
              style: { rotate: open ? "180deg" : "0deg" }
            }
          )
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(Collapsible.Content, { className: "space-y-6 px-4 py-6", children })
      ]
    })
  );
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Accordion,
  Alert,
  AlertDescription,
  AlertTitle,
  Avatar,
  AvatarGroup,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Checkbox,
  Dropdown,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormItemContext,
  FormLabel,
  FormMessage,
  FormSection,
  Group,
  HoverCard,
  IconButton,
  Input,
  Label,
  LoadingIcon,
  Popover,
  RadioGroup,
  SearchInput,
  Select,
  Selector,
  SideDialog,
  Switch,
  Text,
  Textarea,
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  Toaster,
  Tooltip,
  buttonVariants,
  reducer,
  toast,
  useFormField,
  useToast
});
